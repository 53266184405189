import React from 'react';
import config from '../../config/website';

import Nav from './Nav';

const Footer = () => {
  const d = new Date();
  const n = d.getFullYear();

  return (
    <footer className='bg-black text-grey text-xs'>
      <div className='container'>
        <div className='flex flex-wrap py-20 border-b border-grey-darkest md:-mx-6'>
          <div className='w-full uppercase md:w-1/5 md:px-6'>
            <p>{config.siteTitle}</p>
          </div>
          <div className='w-full uppercase md:w-1/5 md:px-6'>
            <p>
              51 Scott’s Run Road <br />
              Maidsville, WV 26541 <br />
              PHONE: 304.333.2564 <br />
              RENTALS: 304.840.3717
            </p>
          </div>
          <div className='w-full md:w-3/5 md:px-6 self-center'>
            <Nav className='footer-nav md:flex font-sans text-xs uppercase mt-4 md:mt-0' />
          </div>
        </div>
        <div className='flex flex-wrap py-10'>
          <div className='w-full text-center text-xs text-grey'>
            <p>
              &copy; {n} Copyright - {config.siteTitle}, Website Design +
              Development:{' '}
              <a
                href='https://mindmergedesign.com'
                target='_blank'
                rel='noopener'
                className='text-grey no-underline'>
                Mind Merge Design
              </a>
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
