import React from 'react';
import { Link } from 'gatsby';

const Nav = props => (
  <ul className={props.className}>
    <li>
      <Link to="/about" activeClassName="active">
        About
      </Link>
    </li>
    <li>
      <Link to="/services" activeClassName="active">
        Services
      </Link>
    </li>
    <li>
      <Link to="/equipment-rentals" activeClassName="active">
        Equipment Rentals
      </Link>
    </li>
    <li>
      <Link to="/safety" activeClassName="active">
        Safety
      </Link>
    </li>
    <li>
      <Link to="/contact" activeClassName="active">
        Contact
      </Link>
    </li>
  </ul>
);

export default Nav;
