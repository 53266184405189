const tailwind = require('../src/styles/tailwind');

module.exports = {
  pathPrefix: '/', // Prefix for all links. If you deploy your site to example.com/portfolio your pathPrefix should be "/portfolio"

  siteTitle: 'Ridge Runner Pipeline Commissioning Specialists', // Navigation and Site Title
  siteTitleAlt: 'RR-PLS', // Alternative Site title for SEO
  siteShortName: 'RR-PLS', // Short name
  siteUrl: 'https://www.ridgerunnerpls.com', // Domain of your site. No trailing slash!
  siteLanguage: 'en', // Language Tag on <html> element
  siteLogo: '../src/images/favicon.png', // Used for SEO and manifest
  siteDescription: 'Pipeline pre-commissioning company',

  // siteFBAppID: '123456789', // Facebook App ID - Optional
  //userTwitter: '@user', // Twitter Username
  ogSiteName: 'Ridge Runner Pipeline Commissioning Specialists', // Facebook Site Name
  ogLanguage: 'en_US', // Facebook Language

  // Manifest and Progress color
  themeColor: tailwind.colors.primary,
  backgroundColor: tailwind.colors.black,
};
