import React, { Component } from 'react';

class Hamburger extends Component {
  constructor(props) {
    super(props);
    this.state = { isActive: false };
  }

  toggleHamburger = () => {
    this.setState({ isActive: !this.state.isActive });
    document.querySelector('#hamburger').classList.toggle('open');
    document.querySelector('.navbar').classList.toggle('opened');
  };

  render() {
    return (
      <div id="hamburger" className="md:hidden h-auto" onClick={this.toggleHamburger}>
        <span />
        <span />
        <span />
        <span />
      </div>
    );
  }
}

export default Hamburger;
